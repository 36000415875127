<template>
  <div>
  <main-navivation></main-navivation>
  <div id="body1">
    
    <base-card>
    <center>
      <h2 class=".text-center">
        2024/25 NORTH MUGIRANGO ONLINE BURSARY
        APPLICATION 
      </h2>
    </center>
    INSTRUCTIONS:
    <br />
    Kindly provide your information correctly and preview to ensure it brings the information correctly.
    <br>
     NB: Submission of incomplete form may lead to disqualification. Click on one of the link below to get application form for your school.Those in high school should have their forms filled by parents and guardians. 
    <br>
    <br>
    Provide all details as much as possible. If you are in high school, make sure you provide your  Birth certificate which you will use to download the form after filling. Those in universities and colleges will provide their national ID number or birth certificate incase you dont have an ID.
    <br>

    Once you fill take to your local chief/ assistant chief to verify the information and stamp.  
    <br>

       <br>

   <p class="text-danger">PLEASE PLEASE PLEASE NOTE: When entering the school name, start typing and select the name that appears. Dont type your own school name when its still available in the selection list. If its missing you can still contact us we help you immedietly insetad of making mistakes that will place you into a wrong school.  </p> 
    <br>
    All duly filled forms should be delivered to N.C.C. Office  at Ekerenyo for processing
     on or before <strong> 7th February 2025</strong>
     <br> 
    <p class="text-danger">When submitting the form to our office attach the following documents:</p> 
    
    1. Student's transcript/ report form
    <br>
    2. Photocopy of parent/guardian's ID card
    <br>
    3. Photocopy of Student's National ID for those in universities and colleges
    <br>
    4. Photocopy of birth certificate
    <br>
  
    5. School/ institution admission letter
    <br>
    <br>

   <small>Already applied? <router-link to="/application/form/">Click here to download form</router-link></small> 


<br>
<br>
    <div class="form-row">
               <div class="col">
                <router-link to="/secondary/application">Secondary school</router-link>
              
               </div>
               <div class="col">
                <router-link to="/college/application">Universities and colleges</router-link>
              
               </div>
               <!-- <div class="col">
                <router-link to="/university/application">Universities </router-link>
              
               </div> -->
               <div class="col">
                <router-link to="/">Back to home page </router-link>
              
               </div>
              
             </div>
  </base-card>
  </div>
</div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      user: "",

      formData: {
        student_names: "",
        student_id: "",
        student_phone: "",
        student_date_of_birth: "",
        student_admission_number: "",
        year_of_admission: "",
        annual_fee: "",
        campus_name: "",
        mode_of_study: "",
        sub_location: "",
        course_of_study: "",

        mother_names: "",
        mother_id: "",
        mother_phone: "",
        father_names: "",
        father_id: "",
        father_phone: "",
        Guardian_names: "",
        Guardian_id: "",
        guardian_phone: "",

        school_name: "",
        school_level: "",

        needy_status: "",
        ward: "",
      },
    };
  },
  // computed: {
  //   courts() {
  //     return this.$store.getters.allCourts;
  //   },
  // },
  // created() {
  //   this.$store.dispatch("LoadCourts");
  // },
  //  mounted() {
  // let user = localStorage.getItem('user');
  // this.user = JSON.parse(user)

  // },
  methods: {
    async createStudent() {
      const response = await axios.post(
        "https://api.roberms.com/nyamoko/student/registration",
        this.formData
      );
      console.log(response);

      this.$router.replace("/");
      // console.log(this.formData);
    },
  },
};
</script>

<style scoped>
#body1 {
  width: 100vw;
  /* background-color: #eaf8d0c9; */
  /* position: fixed; */
  /* margin-left: -220px; */
  /* z-index: 999; */
  justify-content: center;
  align-items: center;
}
.form-control {
  margin: 0.5rem 0;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

input[type="checkbox"] + label {
  font-weight: normal;
  display: inline;
  margin: 0 0 0 0.5rem;
}

input,
textarea {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  font: inherit;
}

input:focus,
textarea:focus {
  background-color: #f0e6fd;
  outline: none;
  border-color: #3d008d;
}

input[type="checkbox"] {
  display: inline;
  width: auto;
  border: none;
}

input[type="checkbox"]:focus {
  outline: #3d008d solid 1px;
}

h3 {
  margin: 0.5rem 0;
  font-size: 1rem;
}

.invalid label {
  color: red;
}

.invalid input,
.invalid textarea {
  border: 1px solid red;
}
</style>

<script></script>
