<template>
  <div>
   <main-navigation></main-navigation>
    <div class="container">

      <!-- <small>   <router-link  :to="`/college/form/`">Click here if you are in College or University to download your form</router-link> </small> -->


    <section class="vh-100">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6 text-black">

        <!-- <div class="px-5 ms-xl-4">
          <i class="fas fa-crow fa-2x me-3 pt-5 mt-xl-4" style="color: #709085;"></i>
          <span class="h1 fw-bold mb-0">Logo</span>
        </div> -->

        <div class="d-flex align-items-center h-custom-2 px-5 ms-xl-4 mt-5 pt-5 pt-xl-0 mt-xl-n5">

          <base-card>
       <small>   <router-link class="text-danger"  :to="`/college/form/`">Click here if you are in College or University to download your form</router-link> </small>
<br>
     <small> Thank you for applying. Check and download your Secondary school application form by
      enterning the required details in the form below
    </small>
      <br />
      <br />
      <div v-if="student">

      </div>
      <form @submit.prevent="findStudent">
        <div class="row">
          <div class="small">
            <input
              type="search"
              class="form-control"
              v-model="idNumber" v-model.trim="idNumber"
            placeholder="Birth certificate number" required 
            />
            <br>
          </div>
          <br>
          <br>
          <div class="">
            <input
              type="submit"
              class="btn btn-primary"
              style="display: block; margin: 1 rem auto"
            />
          </div>
        </div>
      </form>

      <div v-if="student">
        
          click to download form
          <router-link
            :to="`/secondary/application/form/${student.idNumber}`"
          >
            {{ student.studentName }}
          </router-link>
        
      </div>
      <div v-else>
       <small> No student selected. Enter correct Birth ceriticate number to search or apply for bursary now </small>
      </div>



      <!-- {{ student }} -->
      <br />
      <!-- <div class="row">
            <div class="col">
                College application

            </div>
            <div class="col">
                <input
                   type="text"
                   class="form-control"
                   placeholder="Enter student nemi number"
                   
                 />

            </div>
            <div class="col">
                <button class="btn btn-primary">Submit</button>

            </div>

        </div> -->
    </base-card>

        </div>

      </div>
      <div class="col-sm-6 px-0 d-none d-sm-block">
        <img src="../../assets/form.png"
          alt="Login image" class="w-100 vh-100" style="object-fit: cover; object-position: left;">
      </div>
    </div>
  </div>
</section>
</div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      idNumber: "",
      student: null,
    };
  },
  methods: {
    async findStudent() {
      const response = await axios.get(
        "https://api.roberms.com/nyamoko/get/secondary/student/" + this.idNumber
      );
      console.log(response);
      this.student = response.data;
      this.idNumber = response.data.idNumber;
    },
    
  },
  created() {
    //   this.$store.dispatch("LoadStudents");
  },
  computed: {
    studentdetails() {
      return this.$store.getters.studentGetter(2016);
    },
  },
};
</script>
