<template>
  <div>
      
      <main-navigation></main-navigation>
      <base-card>

STUDENTS ALLOCATION

<div class="row mx-md-n5">
  <div class="col px-md-2 card">
<div class="card-header">
    {{ NewsecondarApproved.length }}   Secondary students applicatnts 
    <a href="/edit/secondary/students" class="btn btn-danger">search students</a>
</div>
<div class="card-body">
  <!-- <h5 class="card-title">{{  secondary_processedApproved.length  }} Awarded</h5> -->

  <a href="/secondary/approved/students" class="btn btn-secondary">{{ Newsecondaryschoolsapproved.length }}  Students approved </a>
  <br>
  <br>
  <a href="/secondary/rejected/iebc" class="btn btn-secondary">{{ NewsecondaryRejectedIEBC.length }}  Students outside the consituency </a>
  <br>
  <br>
  <a href="/secondary/rejected/institutions" class="btn btn-secondary">{{ NewsecondaryRejectedWronginstitution.length }}  Students whose institutions are not clear </a>
  <br>
  <br>

  <!-- <p class="card-text"> <strong>{{ secondary_processedApproved.filter(ward=>ward.ward=="Itibo").length }} </strong>  <a href="/secondary/awarded/itibo" class="card-link">Itibo Ward</a>  </p>
   <p class="card-text"> <strong>{{ secondary_processedApproved.filter(ward=>ward.ward=="Ekerenyo").length }} </strong>  <a href="/secondary/awarded/ekerenyo" class="card-link">Ekerenyo Ward</a>   </p>
   <p class="card-text"> <strong>{{ secondary_processedApproved.filter(ward=>ward.ward=="Magwagwa").length }} </strong>  <a href="/secondary/awarded/magwagwa" class="card-link">Magwagwa Ward</a>   </p>
   <p class="card-text"> <strong>{{ secondary_processedApproved.filter(ward=>ward.ward=="Bokeira").length }} </strong>  <a href="/secondary/awarded/bokeira" class="card-link">Bokeira Ward</a>   </p>
   <p class="card-text"> <strong>{{ secondary_processedApproved.filter(ward=>ward.ward=="Bomwagamo").length }} </strong>  <a href="/secondary/awarded/bomwagamo" class="card-link">Bomwagamo Ward</a>   </p> -->
   <a href="/school/names" class="btn btn-primary">View students Approved per institution</a>
</div>
</div>
<br>


 <div class="col px-md-2 card">
<div class="card-header">
 {{ NewcollegeStudents.length }} colleges and Universties applicants
 <a href="/edit/college/students" class="btn btn-danger">search students</a>


</div>
<div class="card-body">
  <!-- <h5 class="card-title">{{  college_processedApproved.length  }} Awarded</h5> -->
  <a href="/college/approved/students" class="btn btn-secondary">{{ NewcollegApproved.length }}  Students approved </a>
  <br>
  <br>
  <a href="/college/rejected/iebc" class="btn btn-secondary">{{ NewcollegRejectedIEBC.length }}  Students outside the consituency </a>
  <br>
  <br>
  <a href="/college/rejected/model" class="btn btn-secondary">{{ NewcollegRejectedNewmodel.length }}  Students under new funding molde </a>
  <br>
  <br>
  <a href="/college/rejected/tvets" class="btn btn-secondary">{{ NewcollegRejectedTvets.length }}  Students In TVETS </a>
  <br>
  <br>
  <a href="/college/rejected/institutions" class="btn btn-secondary">{{ NewcollegRejectedWronginstitution.length }}  Students whose institutions are not clear </a>
  <br>
  <br>


  <a href="/college/names" class="btn btn-primary">View students Approved per institution</a>
</div>


</div>
  
</div>


</base-card>


<br>







 </div>
</template>
<script>
export default {

  computed: {

    NewcollegeStudents (){
        return  this.$store.getters.NewcollegeStudents
    },
    NewcollegApproved (){
        return  this.$store.getters.NewcollegApproved
    },

    NewcollegRejectedIEBC (){
        return  this.$store.getters.NewcollegRejectedIEBC
    },

    NewcollegRejectedNewmodel (){
        return  this.$store.getters.NewcollegRejectedNewmodel
    },

    NewcollegRejectedTvets (){
        return  this.$store.getters.NewcollegRejectedTvets
    },
    NewcollegRejectedWronginstitution (){
        return  this.$store.getters.NewcollegRejectedWronginstitution
    },



    NewsecondarApproved (){
        return  this.$store.getters.NewsecondarApproved
    },
    Newsecondaryschoolsapproved (){
        return  this.$store.getters.Newsecondaryschoolsapproved
    },

    NewsecondaryRejectedIEBC (){
        return  this.$store.getters.NewsecondaryRejectedIEBC
    },

  

 
    NewsecondaryRejectedWronginstitution (){
        return  this.$store.getters.NewsecondaryRejectedWronginstitution
    },






      college_processed (){
          return this.collegeStudents.filter(process=>process.processed =='1')
          // collegeItibostudent:(state)=>state.collegeStudents.filter(area=>area.ward=='Itibo'),


      },
      secondary_processed (){
          return this.secondaryStudents.filter(process=>process.processed =='1')
          // collegeItibostudent:(state)=>state.collegeStudents.filter(area=>area.ward=='Itibo'),


      },
      college_processedApproved (){
          return this.collegeStudents.filter(approval=>approval.tracking_code =='Approved')
          // collegeItibostudent:(state)=>state.collegeStudents.filter(area=>area.ward=='Itibo'),


      },
      college_processedRejected(){
          return this.collegeStudents.filter(approval=>approval.tracking_code =='Rejected')
          // collegeItibostudent:(state)=>state.collegeStudents.filter(area=>area.ward=='Itibo'),


      },

      secondary_processedApproved (){
          return this.secondaryStudents.filter(approval=>approval.tracking_code =='Approved')
          // collegeItibostudent:(state)=>state.collegeStudents.filter(area=>area.ward=='Itibo'),


      },
      secondary_processedRejected (){
          return this.secondaryStudents.filter(approval=>approval.tracking_code =='Rejected')
          // collegeItibostudent:(state)=>state.collegeStudents.filter(area=>area.ward=='Itibo'),


      },


      total_applicants() {
          return this.secondaryStudents.length + this.collegeStudents.length 

      },
      Itibo_student() {
          return this.collegeItibostudent.length + this.secondaryItibostudent.length 

      },
      Ekerenyo_student() {
          return this.collegeEkerenyostudent.length + this.secondaryEkerenyostudent.length 

      },
      Magwagwa_student() {
          return this.collegeMagwagwastudent.length + this.secondaryMagwagwastudent.length 

      },
      Bokeira_student() {
          return this.collegeBokeirastudent.length + this.secondaryBokeirastudent.length 

      },
      Bomwagamo_student() {
          return this.collegeBomwagamostudent.length + this.secondaryBomwagamostudent.length 

      },
      


      secondaryStudents() {
          return  this.$store.getters.secondaryStudents 

          
      },
      collegeStudents() {
          return  this.$store.getters.collegeStudents

          
      },
      collegeItibostudent() {
          return  this.$store.getters.collegeItibostudent

          
      },
      secondaryItibostudent() {
          return  this.$store.getters.secondaryItibostudent

          
      },
      collegeEkerenyostudent() {
          return  this.$store.getters.collegeEkerenyostudent

          
      },
      secondaryEkerenyostudent() {
          return  this.$store.getters.secondaryEkerenyostudent

          
      },
      collegeMagwagwastudent() {
          return  this.$store.getters.collegeMagwagwastudent

          
      },
      secondaryMagwagwastudent() {
          return  this.$store.getters.secondaryMagwagwastudent

          
      },
      collegeBokeirastudent() {
          return  this.$store.getters.collegeBokeirastudent

          
      },
      secondaryBokeirastudent() {
          return  this.$store.getters.secondaryBokeirastudent

          
      },
      collegeBomwagamostudent() {
          return  this.$store.getters.collegeBomwagamostudent

          
      },
      secondaryBomwagamostudent() {
          return  this.$store.getters.secondaryBomwagamostudent

          
      },

      collegeOrphan() {
          return  this.$store.getters.collegeOrphan

          
      },
      secondaryOrphan() {
          return  this.$store.getters.secondaryOrphan

          
      },
      SecondaryOneparent() {
          return  this.$store.getters.SecondaryOneparent

          
      },
      collegeOneparent() {
          return  this.$store.getters.collegeOneparent

          
      },
      collegeBothparents() {
          return  this.$store.getters.collegeBothparents

          
      },
      SecondaryBothparent() {
          return  this.$store.getters.SecondaryBothparent

          
      },
    

    //   NewsecondarApproved() {
    //     return this.$store.getters.NewsecondarApproved;
    //   },
      NewcollegApproved_old() {
        return this.$store.getters.NewcollegApproved
      },
      
      
     

  },
   created() {
      this.$store.dispatch('LoadApprovedCollegestudents');  
      this.$store.dispatch('LoadApprovedSecondarystudents');  
  
  
  
  
},

}
</script>

<style scoped>

.dashboard {
  background-color:azure;
  box-sizing: border-box;
  padding: 3px;
  display: grid;
  grid-template-rows: 1fr 4fr;
  grid-template-columns: 1fr 2fr 4fr;
  grid-gap: 0ch;
  height: 100vh;
  
  grid-template-areas: 
  /* "theader theader theader" */
  "sidebar sheader sheader"
  "sidebar smain smain"

  ;
  


}
.sidebar {
  grid-area: sidebar;
  background-color:rgb(113, 180, 47);

}
.sheader {
  grid-area: sheader;
  background-color: rgb(221, 216, 209);
}
.smain {
  grid-area: main;
  background-color:beige;
}
.theader {
  grid-area: theader;
  grid-area: theader;
}

</style>