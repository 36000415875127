<template>
  <div id="body">
    <main-navigation></main-navigation>

    <base-card>
      <div id="element-to-convert">
        <div class="row">
          <div class="col-lg-3">
            <img width="300" height="150" src="../../assets/ng-cdflogo.jpg" />
          </div>

          <div class="col-lg-9">
            National Government Constituencies Development Fund
            <br />
            North Mugirango constituency,
            <br />
            P.O BOX 105-40500, Nyamira
            <br />
            Email: cdfnorthmugirango@cdf.go.ke || cell: 0721431120
          </div>
        </div>

        <div class="row">
          <div class="col-lg-9">
            <small>Your Ref: BURSARY 2024/2025-NORTH MUGIRANGO </small>
          </div>
          <div class="col-lg-3">Date <strong>  </strong></div>
        </div>

        <br />

        <!-- approved {{ NewcollegeApproved }} -->

        <small>
          <p class="text-primary">To: {{ school_names[0].school_name }}</p>

          Dear sir/Madam
          <br />

          <p class="font-italic">
            <u>
              RE: BURSARY AWARD OF <strong>KSHS {{ total_awarded }} </strong>,
              CHEQUE NUMBER .......................................{{  }},
              DATED ....................................... <strong></strong>
            </u>
          </p>

          Kindly receive and acknowledge the above bursary cheque in respect to
          the following
          <strong>
            {{
                this.NewcollegeApproved.filter((col) => col.school_code == this.school)
                  .length
              }}
            student(s) </strong
          >.
        </small>
        <table class="table table-striped table-bordered table-sm">
          <thead>
            <tr>
              <!-- <th><h3>Client Id</h3></th> -->
               
              <th><small> No.</small></th>
              <th><small>Name</small></th>

              <!-- <th><small> Institution Name</small></th> -->
              <th><small>Adm No.</small></th>
              <th><small>level of study</small></th>
              <th><small>Ward</small></th>
              <th><small>Amount</small></th>
              <!-- <th><h6>Application date</h6></th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(student, index) in NewcollegeApproved" :key="student.id">
              <!-- <td>{{ payment.client_id}}</td> -->

              <!-- <td> <router-link
            :to="`/college/application/form/${student.idNumber }`"
            
          >
          <small> C{{ student.sid }}</small>
          </router-link> </td> -->
              <!-- <td>
                <small> {{ student.id }}</small>
              </td> -->
              <td>
                <small> {{ index + 1 }}</small>
              </td>
              <td>
                <small>{{ student.studentName }}</small>
              </td>

              <td>
                <small>{{ student.adminNumber }}</small>
              </td>

              <td>
                <small>{{ student.student_form }}</small>
              </td>
              <td>
                <small>{{ student.ward }}</small>
              </td>

              <td>
                <small>{{ student.amount_awarded }}</small>
              </td>

              <!-- <td>{{ new Date(student.reg_date).toLocaleString('nl-NL') }}</td> -->
            </tr>
          </tbody>
        </table>
        <br />
        <br />
        <small>
          Any uncredited amount should be reported in writng and returned to the
          North Mugirango Constituency development office
          <br />
          Yours Faithfully
          <br />
          <br />

          <div class="row">
            <div class="col-lg-6">Robert Serem, Fund account Manager</div>
            <div class="col-lg-6">
              Stamp and signature:
              .........................................................
            </div>
            <br />
            <br />
          </div>
        </small>
        <div class="row">
          <br />
        </div>
      </div>

      <div class="row">
        <!-- <form @submit.prevent="isssueCheque">
          <div class="row">
            <div class="col">
                <input 
                 class="form-control"
                 placeholder="Cheque No"
                 v-model="newData.cheque_number"
                 required
                 
               />
             
            </div>
            
            <div  class="col"><base-button>Issue Cheque</base-button></div>
           
            

          </div>
        </form> -->
        <br>
        <br>
        <!-- <div class="col">
          <base-button @click="issueCheque">Issue Cheque</base-button>
        </div> -->

        <!-- <div class="col">
          <base-button @click="exportToPDF">Download Letter</base-button>
        </div> -->
      </div>
      <br>
     
      <button @click="exportToPDF">Download Letter</button>


      <!-- <button @click="exportToPDF">Download</button> -->
      <br />
      <router-link to="/">Go back</router-link>
    </base-card>
  </div>
</template>
<script>
import axios from "axios";
import html2pdf from "html2pdf.js";
export default {
    data() {
    return {
      
      newData: {
        cheque_number: "",
        cheque_amount: this.total_awarded,
        
        school_id: this.$route.params.school,
      },
    };
  },
  methods: {
    exportToPDF() {
  var element = document.getElementById("element-to-convert");
  let fileName = this.school_names[0].school_name + ".pdf"; // Define filename

  html2pdf()
    .set({
      margin: [10, 10, 10, 10], 
      filename: fileName, // Set filename
      
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: {
        dpi: 300, 
        scale: 3, 
        logging: true,
        letterRendering: true,
        scrollX: 0,
        windowWidth: element.scrollWidth,
      },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    })
    .from(element)
    .toPdf()
    .get('pdf')
    .then(function (pdf) {
      pdf.internal.scaleFactor = 1.5;
      pdf.save(fileName); // Explicitly pass the filename here
    });
},



    async isssueCheque() {
      const response = await axios.put(
        "https://api.roberms.com/nyamoko/issue/cheque/college/",
        this.newData,
       console.log(response)
      );

      console.log(response);
      console.log(this.formData);

      this.$router.push("/filter/colleges");
      console.log(this.student_id);
    },
  },
  computed: {
    wards() {
      return this.$route.params.ward
        .split(" ")
        .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
        .join(" ");
    },
    school() {
      return this.$route.params.school;
    },
    total_awarded() {
        return this.NewcollegeApproved
          .reduce((acc, item) => acc + parseInt(item.amount_awarded), 0);
      },
    //   total_amount() {
    //         return this.students.filter(college=>college.processed_by=="1" ).reduce((total, item)=>{
    //             return total + item.main_contact_number;
    //         },0)

    //     },

    school_names() {
      return this.$store.getters.specificCollegeName(this.$route.params.school);
    },
    cheque_number() {
      return this.$store.getters.college_names.filter((col) => col.processed_by == this.school);
    },
    colleges() {
      return this.$store.getters.colleges;
    },
    students() {
      return this.$store.getters.collegeStudents.filter(
        (award) => award.tracking_code == "Approved"
      );
    },
    collegeAwarded() {
      return this.$store.getters.collegeSchoolstudents(this.school);
    },
    NewcollegeApproved() {
        return this.$store.getters.NewcollegeApproved(this.school).sort((a, b) => a.studentName.localeCompare(b.studentName));

      },
      
      
  },
  created() {
    this.$store.dispatch("LoadApprovedCollegestudents");
    console.log(this.total_awarded);
    // console.log(this.students.filter((col) => col.processed_by == this.school));
  },
};
</script>
<style scoped>
#element-to-convert {
  page-break-inside: avoid; /* Prevents breaking elements across pages */
  overflow: hidden;
}

.content-section {
  page-break-before: auto;
  page-break-inside: avoid;
  page-break-after: auto;
}

.page-break {
  page-break-before: always; /* Forces a new page */
}
</style>