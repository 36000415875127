<template>
  <div>
   <main-navigation></main-navigation>
    
   <base-card> 
  <div class="form-outline">
<input type="search" id="form1" class="form-control" v-model="search" placeholder="Search student"  aria-label="Search"  />
</div>
</base-card>
<!-- students {{ students }} -->
  <base-card>
<table class="table table-striped table-bordered">
      <thead>
          <tr >
              <!-- <th><h3>Client Id</h3></th> -->
              <th><small>Serial number</small></th>
              <th><small>Name</small></th>

              <th><small>Id number </small></th>

              <th><small> Institution Name</small></th>

              <th><small>Admission Number</small></th>
              <th><small>Class</small></th>
              <th><small>Form status</small></th>
              <th><small>Needy status</small></th>
              <th><small>Remarks</small></th>
              <!-- <th><small>Actions</small></th> -->
              <!-- <th><h6>Application date</h6></th> -->
          </tr>
          </thead> 
          <tbody>
              <tr v-for="student in filteredStudents.slice(0,5)" :key="student.id">
                  
                  <!-- <td>{{ payment.client_id}}</td> -->
                  <!-- <td><small>NAMES: {{ student.studentName }} <p class="font-weight-bold">ID No: {{ student.student_id }} </p>  YOB:  {{ student.student_yob}}</small></td> -->
                  <td><small>H{{ student.sid}}</small></td>
                  <td><small>{{ student.studentName}}</small></td>

                  <td><small>{{ student.idNumber}}</small></td>

                  
                  <td><small>{{ student.schoolName}}</small></td>


                  <td><small>{{ student.adminNumber}}</small></td>
                  <td><small>{{ student.student_form}}</small></td>
                  <td><small>{{ student.tracking_code}}</small></td>


                
                  <td><small>{{ student.needy_status}}</small></td>
                  <td><small>{{ student.user_remarks}}</small></td>

                  <!-- <td ><small><a class="btn btn-primary" :href="'/edit/college/student/' + student.student_id">Show details</a></small></td> -->
                  <!-- <td><small> <router-link to="`/verify/college/student/${student.student_id}`"  class="btn btn-primary"> Show details</router-link>  </small></td> -->
                  <!-- <td><small> <form > <base-button>Approve</base-button> </form> <br> <form > <base-button>Reject</base-button> </form>  <br>  STATUS:  </small></td> -->



                 
                  <!-- <td>{{ new Date(student.reg_date).toLocaleString('nl-NL') }}</td> -->
                  
              </tr>
          </tbody>
  </table>
  
</base-card>

<!-- <router-link to="/receive/payment">Receive payment</router-link> -->
</div>
</template>
<script>
import axios from "axios";


export default {
  data() {
  return {
    search: "",
  };
},
  computed: {
      students() {
          return  this.$store.getters.NewsecondarApproved//.filter(process=>process.tracking_code =='Approved')

          
      },
      filteredStudents() {
      return this.students.filter((student)=>student.studentName.toLowerCase().includes(this.search.toLowerCase())| student.idNumber.toString().includes(this.search.toLowerCase())).slice(0,5)
    //return this.students.filter((student)=>student.student_name.toLowerCase().includes(this.search.toLowerCase())).slice(0,6)
      
     
  },

  },
  actions: {
      async verify() {
    const response = await axios.post(
      "https://api.roberms.com/nyamoko/verify/college/",
      this.formData.student_id, this.formData.processed
    );
    
    console.log(response);
    console.log(this.formData)

    this.$router.push("/verify/college"+"?" + this.idNumber);
    console.log(this.student_id);
  },


  },
   created() {
  this.$store.dispatch('LoadApprovedSecondarystudents');  
  
  
},

}

</script>
