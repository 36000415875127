<template>
    <div id="body">
      <main-navigation></main-navigation>
  
      <base-card>
        <div id="element-to-convert">
          <div class="row">
            <div class="col-lg-3">
              <img width="300" height="150" src="../../assets/ng-cdflogo.jpg" />
            </div>
  
            <div class="col-lg-9">
              National Government Constituencies Development Fund
              <br />
              North Mugirango constituency,
              <br />
              P.O BOX 105-40500, Nyamira
              <br />
              Email: cdfnorthmugirango@cdf.go.ke || cell: 0721431120
            </div>
          </div>
  <!-- iebc {{ NewcollegRejectedIEBC }} -->
         
  <!-- ins {{ NewcollegRejectedWronginstitution }} -->
          <br />
  
          <!-- approved {{ NewcollegeApproved }} -->
  
    Students who did not give correct institution infromation
          <table class="table table-striped table-bordered table-sm">
            <thead>
              <tr>
                <!-- <th><h3>Client Id</h3></th> -->
                 
                <th><small>Serial No.</small></th>
                <th><small>Name</small></th>
            
                <!-- <th><small>Phone number</small></th> -->
  
                <!-- <th><small> Institution Name</small></th> -->
                <!-- <th><small>Admission Number</small></th> -->
                <th><small>Year</small></th>
                <th><small>Ward</small></th>
                <!-- <th><small>Mother name</small></th> -->
             
                <!-- <th><small>Father name</small></th> -->
              
                <!-- <th><small>Guardian name</small></th> -->
                <th><small>Institution Name</small></th>
                <!-- <th><h6>Application date</h6></th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="student in NewcollegRejectedWronginstitution" :key="student.id">
                <!-- <td>{{ payment.client_id}}</td> -->
  
                <td> <router-link
              :to="`/college/application/form/${student.idNumber }`"
              
            >
            <small> C{{ student.sid }}</small>
            </router-link> </td>
                <!-- <td>
                  <small> {{ student.id }}</small>
                </td> -->
                <td>
                  <small> {{ student.studentName }}</small>
                </td>
               
                <!-- <td>
                  <small> {{ student.main_contact_number }}</small>
                </td> -->
                <!-- <td>
                  <small>{{ student.college_name }}</small>
                </td> -->
<!--   
                <td>
                  <small>{{ student.adminNumber }}</small>
                </td>
   -->
                <td>
                  <small>{{ student.student_form }}</small>
                </td>
                <td>
                  <small>{{ student.ward }}</small>
                </td>
  
                <!-- <td>
                  <small>{{ student.mother_names }}</small>
                </td> -->
                <td>
                  <small>{{ student.schoolName }}</small>
                </td>
  
              
                <!-- <td>
                  <small>{{ student.father_names }}</small>
                </td> -->
               
                <!-- <td>
                  <small>{{ student.guardian_name }}</small>
                </td> -->
  
             
  
  
  
                <!-- <td>{{ new Date(student.reg_date).toLocaleString('nl-NL') }}</td> -->
              </tr>
            </tbody>
          </table>
          <br />
          <br />
       
          <div class="row">
            <br />
          </div>
        </div>
  
        <div class="row">
          <form @submit.prevent="isssueCheque">
            <div class="row">
              <div class="col">
                  <input 
                   class="form-control"
                   placeholder="Cheque No"
                   v-model="newData.cheque_number"
                   required
                   
                 />
               
              </div>
              
              <div  class="col"><base-button>Issue Cheque</base-button></div>
             
              
              <!-- <div  class="col-xs-12 col-sm-6 col-md-3 col-xl-3"><base-button>Issue Cheque</base-button></div> -->
  
            </div>
          </form>
          <br>
          <br>
          <!-- <div class="col">
            <base-button @click="issueCheque">Issue Cheque</base-button>
          </div> -->
  
          <!-- <div class="col">
            <base-button @click="exportToPDF">Download Letter</base-button>
          </div> -->
        </div>
        <br>
       
        <button @click="exportToPDF">Download Letter</button>
  
  
        <!-- <button @click="exportToPDF">Download</button> -->
        <br />
        <router-link to="/">Go back</router-link>
      </base-card>
    </div>
  </template>
  <script>
  import axios from "axios";
  import html2pdf from "html2pdf.js";
  export default {
      data() {
      return {
        
        newData: {
          cheque_number: "",
          cheque_amount: this.total_awarded,
          
          school_id: this.$route.params.school,
        },
      };
    },
    methods: {
      exportToPDF() {
        var element = document.getElementById("element-to-convert");
        html2pdf(element, {
          margin: [5, 15, 10, 15], //top, left, buttom, right
          filename: this.school_names[0].school_name + ".pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: {
            dpi: 192,
            scale: 2,
            logging: true,
            letterRendering: true,
          },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        });
      },
  
      async isssueCheque() {
        const response = await axios.put(
          "https://api.roberms.com/nyamoko/issue/cheque/college/",
          this.newData,
         console.log(response)
        );
  
        console.log(response);
        console.log(this.formData);
  
        this.$router.push("/filter/colleges");
        console.log(this.student_id);
      },
    },
    computed: {
   
 
  
        NewcollegRejectedWronginstitution (){
        return  this.$store.getters.NewcollegRejectedWronginstitution
    },

        
    },
    created() {
      this.$store.dispatch("LoadApprovedCollegestudents");
      console.log(this.total_awarded);
      // console.log(this.students.filter((col) => col.processed_by == this.school));
    },
  };
  </script>
  